/* ==========================================================================
   Pie bar
   ========================================================================== */

/**
 * c-chart-pie
 * The chart bar element as seen in a player card on the player details page
 */


.c-chart-doughnut {
    &__legend-list {
        margin: 0;
        list-style-type: none;
        min-width: rem(200);
    }

    &__legend-item {
        margin-bottom: rem(5);
    }

    &__legend-marker {
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: rem(5);
        width: rem(20);
        height: rem(20);
        border-radius: 50%;
    }
}
