label {
    font-weight: 700;
    color: $dark-gray;
}

input {
    touch-action: manipulation;// disables the double tap zoom on input fields
    &:focus {
        outline: none;
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    padding: rem(4 0 4 0);
    width: 100%;

    &, &:focus {
        border-top: none;
        border-right: none;
        border-left: none;
    }

    &:focus {
        &::placeholder {
            color: get-color(primary);
        }
    }
}

label>[type='checkbox'] {
    margin-right: rem(15);
}

select {
    &, &:focus {
        display: inline-block;
        width: 100%;
        background-color: transparent;
        border: none;
        color: $dark-gray;
        margin: rem(0 0 15 0);
        padding: rem(0 25 0 0);
        border-bottom: rem(1) solid $black;
        box-shadow: none;
        background-size: rem(20) rem(30);
        background-position: right rem(-24) center;
        background-image: svg-url('<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.4142" version="1.1" viewBox="0 0 100 100" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(2.3117e-16 .81604 -.81604 2.3117e-16 109.57 -38.133)"><path d="m139.18 73c0-0.857-0.429-1.821-1.072-2.464l-49.928-49.929c-0.643-0.643-1.608-1.071-2.465-1.071s-1.821 0.428-2.464 1.071l-5.357 5.357c-0.643 0.643-1.072 1.607-1.072 2.465 0 0.857 0.429 1.821 1.072 2.464l42.107 42.107-42.107 42.107c-0.643 0.643-1.072 1.607-1.072 2.464 0 0.965 0.429 1.822 1.072 2.465l5.357 5.357c0.643 0.643 1.607 1.071 2.464 1.071s1.822-0.428 2.465-1.071l49.928-49.929c0.643-0.643 1.072-1.607 1.072-2.464z" fill-rule="nonzero"/></g></svg>');
    }

    &.has-selected {
        color: $body-font-color;
    }
}

/*
 * Dark theme
 */
.c-form--dark {
    color: $white;
    label {
        color: $white;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    input {
        background-color: $black;
    }

    [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
        border-bottom: rem(1) solid $white;
        color: $white;

        &::placeholder {
            color: rgba($white, 0.8);
        }

        &:focus {
            color: $white;
            border-bottom: rem(1) solid $white;
            &::placeholder {
                color: $white;
            }
        }
    }

    select {
        color: rgba($white, 0.8);
        border-color: $white;
        background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g transform="matrix(-2.02973e-16,-1.26217,1.05791,-1.70124e-16,4.51006,67.0393)"><path d="M0.1,43L0,43.15L0.28,43.3L25.25,86L27,85L2.43,43L27,1L25.24,0L0.24,42.7L0,42.85L0.1,43Z" style="fill:white;fill-rule:nonzero;"/></g></svg>');

        &.has-selected {
           color: $white;
        }

        option {
           color: $body-font-color;
        }
    }

  & .u-checkbox {
    background-color: $secondary-color;
    border: rem(1) solid $white;
    box-shadow: 0 0 0 4px get-color(secondary);
  }

}


/*
 * Megamenu adjustments
 */

 .c-form--megamenu {
     max-width: rem(360);
 }

//----------------- Floating Labels https://foundation.zurb.com/building-blocks/blocks/floated-label-wrapper.html

.u-floated-label-wrapper {
    position: relative;

    label {
      font-size: $small-font-size * .75;
      opacity: 0;
      padding: 0;
      position: absolute;
      top: rem(32);
      transition: all 0.10s ease-in;
      z-index: -1;

      input[type=text],
      input[type=email],
      input[type=password] {
        border-radius: rem(4);
        font-size: 1.75em;
        padding: 0.5em;
      }

      &.has-show-label {
        opacity: 1;
        top: rem(-10);
        z-index: 1;
        transition: all 0.3s ease-in;
      }
    }

  }

// Colored checkboxes
.u-colored-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: rem(25);
        width: rem(25);
        background-color: $light-gray;
        transition: background .3s ease-in-out;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    &__name {
        padding-left: rem(35);
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .u-colored-checkbox__checkmark{
        background-color: darken($light-gray, 10%);
    }


    /* When the radio button is checked, add a blue background */
   input:checked ~ .u-colored-checkbox__checkmark {
        background-color: $primary-color;

        &:after {
            display: block;
        }
    }

    .u-colored-checkbox__checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }

}


// Date Input fields
.u-datepicker {
    input { // input is inserted via vue
        background-color: $white;
        cursor: pointer;
        border-bottom: rem(1) solid $black;
    }
}

.is-loading-options {
    background-color : $light-gray;
    cursor: not-allowed;
    position: relative;
    //color: $light-gray;
    border-bottom: rem(1) solid get-color('alert');
}

button[disabled] {
    background-color: $light-gray;
}
