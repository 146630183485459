/* ==========================================================================
   Districts
   ========================================================================== */

/**
 * c-districts
 * This block is used for the district list found for example on the homepage
 */

.c-districts {

    &__list {
        margin: 0;
        list-style-type: none;
    }

    &__item {
        margin-bottom: $global-margin;

    }
}

.c-district {
    padding: $global-margin;
    position: relative;
    text-align: center;
    box-shadow: $global-shadow;

    &__name {
        color: $white;
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        font-size: rem(22);
        text-transform: uppercase;
        line-height: 1.4;
        height: rem(40); // Height is needed to keep the required spacing
    }

    .c-button:hover {
        border-color: $black;

        .c-button__label {
            color: $black;
        }
    }

    @include breakpoint(small only) {

        display: flex;
        flex-wrap: wrap;
        padding: rem(20 10 20 0);


        .c-district__shield {
            flex: 1 0 auto;
            order: 1;
            height: auto;
        }

        .c-district__logo {
           width: rem(120);
        }

        .c-district__name {
            font-size: rem(16);
            height: auto;
            width: 80%;
            margin: 0 auto rem(40) auto;
        }

        .c-district__button {
            flex: 1 1 auto;
            order: 2;
        }

    }
}

// Background colors

@each $name, $colors in $district-colors {

    .c-district--#{$name} {
        @include district-background-color($colors);
    }

}



/* Variations
   ========================================================================== */


   .c-districts--megamenu {

        .c-district {
            padding: rem(15 8 5 8);
            min-height: 100%;
            box-shadow: none;
        }

        .c-district__name {
            height: auto;
            font-size: rem(16);
            text-transform: none;
            margin-bottom: 0;
            transform: translateY(-8px);
            line-height: 1;
        }

        .c-districts__item {

            padding: rem(5);

            &:last-child() {
                margin-right: $global-margin;
            }

            @media all and (min-width: rem(1200)) {
                min-width: rem(170);
                margin-right: $global-margin;
            }



        }

        .c-districts__list {

            @media all and (min-width: rem(1200)) {
                flex-wrap: nowrap;
            }
        }

   }
