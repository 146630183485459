/* ==========================================================================
   Footer Navigation
   ========================================================================== */


/**
 * c-footer-nav
 * Footer navigation styling
 */

 .c-footer-nav {

    &__list {
        display: flex;
        margin: $global-margin 0 0;
        list-style-type: none;

        @include breakpoint(large) {
            margin-top: 0;
        }
    }

    &__item {
        flex: 0 0 auto;
        margin-right: rem(5);

        @include breakpoint(medium) {
            margin-right: rem(30);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        color: $white;
        font-weight: 700;
        padding: rem(5) rem(5) rem(5) 0; // give link some padding for touch and give visual separation

        @include breakpoint(medium) {
            font-weight: 400;
        }

        &:hover {
            color: $body-font-color;
        }
    }
 }
