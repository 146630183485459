/* ==========================================================================
   Search results
   ========================================================================== */

/**
 * c-search-results
 * Styling for the search results used on the Spielbetrieb pages
 */

.c-search-results {
    margin-top: $global-margin;
}
