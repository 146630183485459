/* ==========================================================================
   Map Component
   ========================================================================== */

/**
 * c-map
 * This component is a content wrapper that gives the map styling
 */

.c-map{
    &__mapcanvas {
        min-height: rem(300);
        margin: rem(36) 0;
    }

    &__infowindow {
        overflow: hidden;
        p {
            color: $body-font-color;
            margin-bottom: rem(10);
        }
        dl {
            color: $body-font-color;
        }
    }

    &__infowindow-heading {
        color: $primary-color;
        font-size: rem(20);
    }

    &--tvmmap {
        .c-map__mapcanvas {

            @include breakpoint(large) {
                min-height: rem(600);
            }
        }

        .c-map__infowindow {
            padding: rem(10);
            min-width: rem(320);
        }
    }
}
