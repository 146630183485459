/* ==========================================================================
   Heading component
   ========================================================================== */

/**
 * c-heading
 * A style that overrides the default heading font sizes. Sometimes we need an H1 that is the size of an H2 or H3 for example.
 */


/* Variations
   ========================================================================== */

 
   @each $size, $headers in $header-styles {
    @include breakpoint($size) {
        @each $header, $header-defs in $headers {
            $font-size-temp: 1rem;
            .c-heading--#{$header} {
                
                font-size: rem-calc(map-get($header-defs, font-size));
                margin-top: map-get($header-defs, margin-top);
                margin-bottom: map-get($header-defs, margin-top);

                @if map-has-key($header-defs, font-size) {
                    $font-size-temp: rem-calc(map-get($header-defs, font-size));
                } @else if map-has-key($header-defs, fs) {
                    $font-size-temp: rem-calc(map-get($header-defs, fs));
                }
                @if map-has-key($header-defs, line-height) {
                    line-height: rem-calc(map-get($header-defs, line-height), $font-size-temp);
                } @else if map-has-key($header-defs, lh) {
                    line-height: rem-calc(map-get($header-defs, lh), $font-size-temp);
                } @else if $size == $-zf-zero-breakpoint {
                    line-height: rem-calc($header-lineheight, $font-size-temp);
                }
            }
        }
    }
}