/* ==========================================================================
   Mobile Button component
   ========================================================================== */


/**
 * c-mobile-button
 * Controls styling and placement of mobile button in the header
 */

 .c-mobile-button {
    width: 100%;
    text-align: right;
    position: relative;
    left: rem(28);

    &__icon {
        width: rem(30);
        height: rem(30);
        fill: $black;
        margin-right: rem(10);
    }

    &.is-open {
        .c-mobile-button__icon {
            width: rem(25);
            height: rem(25);
        }
    }
}
