/* ==========================================================================
   Newsletter form component
   ========================================================================== */


/**
 * c-newsletterform
 * Newsletter form eg. in the footer 
 */

 .c-newsletterform {

    &__fields {

        @include breakpoint(medium down) {

            max-width: rem(291);

        }
    }


 }