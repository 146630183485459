/* ==========================================================================
   Contact Card Component
   ========================================================================== */

/**
 * c-contact-card
 * This component styles the content of the contact card
 */

.c-contact-card{

    max-width: rem(560);

    &__media {
        text-align: center;

        @include breakpoint(medium) {
            margin-right: $global-margin;
            text-align: left;
        }
    }

    &__image {
        border-radius: 50%;
        width: 100%;
        max-width: rem(150);

        @include breakpoint(large) {
            min-width: rem(170);
            max-width: 100%;
        }
    }

    // Typography
    &__position {

        font-family: $header-font-family;
        font-weight: 700;
        margin-bottom: rem(5);

        @include breakpoint(small only) {
            text-align: center;
        }
    }

    &__email {
        font-weight: 700;

    }

    &__name {
        font-size: rem(20);
        margin-bottom: rem(10);

        @include breakpoint(small only) {
            text-align: center;
            margin-bottom: $global-margin;
        }

    }

    &__email {
        margin-bottom: 0;
    }

    // if no image, we include an svg
    &__no-user-icon {
        width: 100%;
        height: 100%;
        max-width: rem(70);
        max-height: rem(70);
        fill: $light-gray;

        @include breakpoint(large) {
            max-width: rem(170);
            max-height: rem(170);
        }
    }

}



/* Variations
   ========================================================================== */

   // Small variation is for when the card is in a large-4 column. For example on the tournament details page.

.c-contact-card--small {
    padding: rem(15);

    .c-contact-card__no-user-icon {
        max-width: rem(70);
        max-height: rem(70);
    }

}
