/* ==========================================================================
   Partner Logo List component
   ========================================================================== */


/**
 * c-partner-logos
 * Controls styling of logo list used in footer
 */

 .c-partner-logos {
     margin: 0 auto $global-margin auto;
     list-style-type: none;
     display: flex;
     justify-content: space-between;
     align-items: center;
     flex-wrap: wrap;

     &__item {
         flex: 1 0 50%;
         text-align: center;
         padding: rem(25);
         max-width: rem(172); // width of logo

         @include breakpoint(medium) {
            flex: 1 0 33.33333%;
         }

         @include breakpoint(large) {
            flex: 1 0 auto;
         }

     }

     &__logo {
        min-width: 100%;
        
        &.is-lazyloaded {
            opacity: .7;
            transition: opacity .3s ease-in-out;
        }

        &:hover {
            opacity: 1;
        }
     }

     @include breakpoint(large) {
        margin-left: rem(- $global-margin); // padding of grid has too much space left and write of first and last item. Negative Margins on the container take care of that.
        margin-right: rem(- $global-margin);
     }

 }