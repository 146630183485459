/* ==========================================================================
   Logo component
   ========================================================================== */


/**
 * c-tvm-logo
 * Controls styling and placement of TVM Logo in the header
 */

.c-tvm-icon {
    width: rem(18);
    height: rem(18);
    display: inline;
    transform: translateY(-2px);
    margin-right: rem(2);
    vertical-align: middle;
}



