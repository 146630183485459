/* ==========================================================================
   Ranking Icon Component
   ========================================================================== */

/**
 * c-ranking-icon
 * This component is on Mannschaften Tables. It shows if the team has risen or fallen
 */

.c-ranking-icon  {
    display: inline-block;
    position: relative;
    padding: rem(10);
    height: rem(20);
    width: rem(20);
    text-align: center;
    vertical-align: text-bottom;
    margin: 0 rem(5);
    border-radius: rem(5);

    &__icon {
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;
        width: rem(10);
        height: rem(10);
        fill: $white;
        display: block;
        transform: translate(-50%, -50%);
    }


/* Variations
   ========================================================================== */

    &--rise {
        background-color: $primary-color;
    }

    &--fall {
        background-color: get-color('alert');
    }
}
