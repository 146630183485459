/* ==========================================================================
   Club Detail Page
   ========================================================================== */

/**
 * c.club-details
 * This component styles the content of the club detail page
 */

.c-club {

    &__icon {
        width: 100%;
        max-width: rem(165);
        max-height: rem(165);
    }

}
