/* ==========================================================================
   Box List Layout
   ========================================================================== */

/**
 * l-boxes
 * This layout is used for the competition layout found on the tournament detail page
 */

.l-boxes{
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    //-------------------- Different widths for different counts

    // 1 count

    &__item {
        flex: 1 0 100%;
        padding: $global-margin/2;
    }

    &--2, &--3, &--4 {

        .l-boxes__item {
            flex: 0 1 50%;
        }
    }

    &--6, &--5 {

        .l-boxes__item {
            flex: 0 1 33%;
        }
    }

    &--7 {

        .l-boxes__item {
            flex: 0 1 25%;
        }
    }

    @include breakpoint(small only) {

        &__item {
            flex: 1 0 50% !important; // needed to override the designated count classes
            padding: $global-margin/2;
        }

    }
}
