/* ==========================================================================
   Header Search component
   ========================================================================== */

/**
 * c-header-search
 * This component styles all specific elements of the header search component.
 */

.c-header-search {

     // default settings
    position: fixed;
    top: rem(90);
    right: rem(20);
    left: rem(20);
    z-index: 10;

    // Animation setup
    transform: translateX(105%);
    transition: transform 1s ease-in-out;
    opacity: 0;
    visibility: hidden;
    display: none; // hide on small screens

    &__close {
        margin-left: rem(10);
        display: inline-block;
    }

    &__close-icon {
        width: rem(15);
        height: rem(15);
        position: relative;
        cursor: pointer;
        fill: $black;
        transition: fill .3s ease-in-out;

        &:hover {
            fill: $primary-color;
        }
    }

    &__submit {
        display: inline-block;

        .c-button {
            height: rem(50);
        }
    }

    @include breakpoint(medium) {
        display: inline-block;
    }

    @media all and (min-width: rem(1025)) {
        position: absolute; // on smaller screens that could use ios, we need a position fixed to overcome a display bug. On larger screens, we go to absolute behaviour
    }

    // Top placement
    @media screen and (min-width: rem($header-medium-breakpoint)) {
        top: rem(33);
        right: rem(20);
        left: inherit;
        width: 100%;
        max-width: rem(780);
    }

    // Dynamic forms

    &__form {
       @include visually-hidden;

       &.is-active {
            @include visually-hidden-undo;
       }
    }

    .c-searchfield__dropdown-wrapper { // hide image, as it inteferes with legability
        @media all and (max-width: rem(1150)) {
            background-image: none;
        }
    }

}
