/* ==========================================================================
   Courses
   ========================================================================== */

/**
 * c-courses
 * Styling for the latest news listing
 */

.c-courses {

    &__list {
        margin: $global-margin 0;
        list-style-type: none;
    }

    &__item {
        background-color: $primary-light-3;
        font-weight: $header-font-weight;
        padding: rem(10);

        &:nth-child(even){
            background-color: $white;
        }
    }

    &__link {
        display: block;
        color: $body-font-color;
        padding: rem(5 0 5);

        &:hover {
            color: $primary-color;
        }
    }

    &__type {
        text-transform: uppercase;
        color: $primary-color;
        letter-spacing: rem(1);
        font-size: $small-font-size;

    }

    &__headline {
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        text-align: center;
    }

    &--max-width {
        max-width: rem(770);
        margin: auto;
    }

    &--dark {

        .c-courses__headline {
            text-align: left;
            text-transform: uppercase;
            font-family: $header-font-family;
            font-size: rem(map-deep-get($header-styles, "medium", "h4", "font-size"));
            margin-top: rem(map-deep-get($header-styles, "medium", "h6", "margin-top"));
            font-weight: 800;
        }

        .c-courses__item {
           background-color: $dark-gray-1 !important;
           border-bottom: rem(1) $black solid;
        }

        .c-courses__link, .c-courses__type {
            color: $white;
        }

        .c-courses__link:hover {
            color: $primary-color;
        }
    }

    &--no-pattern {
        .c-courses__item {
            background-color: $primary-light-4;
            border-bottom: rem(3) $white solid;

            &:last-child {
                border: none;
            }
        }
    }
}


.c-courses-filter {
    margin-top: $global-margin * 2;
    text-align: center;

    @include breakpoint(large) {
        text-align: left;
    }

    &__list {
        list-style-type: none;
        margin: $global-margin 0 $global-margin*2 0;
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            margin: rem(0 0 20 0);
        }
    }

    &__item {
        margin: rem(5 15 5 0);

        @include breakpoint(medium) {
            margin: rem(5 15);
        }

    }

    &__label {
        white-space: nowrap;
    }

    &__name {
        background-color: $primary-light-4;
        display: inline-block;
        border-radius: $global-radius;
        padding: rem(10);
        transition: background .3s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: $primary-color;
            color: $white;
        }
    }

    .c-search-filters__toggle-label {
        padding-right: 0;
    }
}
