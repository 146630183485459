/* ==========================================================================
   Table general stylings
   ========================================================================== */

/**
 * Table style settings for generic tables that are not included in the Foundation settings
 */

 thead th, tr th {
     font-weight: 400;
     font-size: $small-font-size;
 }

 thead, tbody {
     border: none;
 }

 tr.o-table-final-results {
    background-color: $white !important; // need to override standard styling
    border-top: rem(1) solid $dark-gray;
 }


 /*
 * Verdienstmedaille table (CB Field)
*/

.c-medals-table {
// Table resets to give starting point for different table design
    margin-top: rem(50);
    font-size: rem(16);
    @include breakpoint(large) {
        font-size: rem(20);
    }

    thead, tbody, tbody tr:nth-child(odd) {
        background: transparent;
    }
    tbody th,
    tbody td {
        padding-left: 0;
        padding-right: 0;
        padding-top: rem(25);

        @include breakpoint(large) {
            padding-top: rem(35);
        }
    }

    thead th,
    tr th {

        font-weight: $header-font-weight;

        @include breakpoint(large) {
            font-size: rem(20);
        }
    }

    th:last-child {
        text-align: right;
    }

    tr {
        border-bottom: 2px solid $medium-gray;
    }

    th,
    td:first-child {

        @include breakpoint(large) {
            padding-left: rem(50);
        }
    }

    &__subtable {
        margin-bottom: 0;
        tbody td {
            padding-top: 0;
        }

        td:last-child {
            text-align: right;
        }

        @include breakpoint(large) {
            tbody td {
                padding-bottom: rem(50);
            }

            tr:last-child {
                td {
                    padding-bottom: rem(20);
                }
            }
        }
    }

    .c-medals-table__subtable tr, tr:last-child, th {
        border-bottom: none;
    }
}

