/* ==========================================================================
   Search field component
   ========================================================================== */

/**
 * c-db-searchfield
 * This component styles search field top area
 */

 .c-db-searchfield {
    margin: $global-margin * 2 0;

    &__content {
        padding: rem(25 15);
        background-color: $primary-light-4;
        box-shadow: inset rem(0 0 30 -5) $global-shadow-color;

    }

    &__input {
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: $primary-light-4;
        border: none;
        height: rem(50);

        &:focus {
            outline-color: $primary-light-4;
        }
        
        &::placeholder {
            font-size: rem(20);
        }
    }

    &__label {
        display: block;
        font-size: rem(24);
        color: $black;
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        margin-bottom: $global-margin;
    }

    &__question-icon {
        width: rem(28);
        height: rem(28);
        fill: lighten($dark-gray,15%);
    }

    &__search-icon {
        width: rem(36);
        height: rem(36);
        fill: $black;
    }


 }
