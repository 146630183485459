/* ==========================================================================
   Search filters
   ========================================================================== */

/**
 * c-search-filters
 * Styling for the search filters used on the Spielbetrieb pages
 */

.c-search-filters {
   box-shadow: $global-shadow;
   background-color: $white;
   padding: $global-margin*2 0 0;

   @include breakpoint(small only) {
       // add more user friendly margins to form elements on small devices
       select, .c-input-zipcode {
           margin: rem(10) 0;
       }
   }

   @include breakpoint(medium) {
      padding: $global-margin*2 0 $global-margin;
   }

   &__search-container {
       max-width: rem(970);
       margin: 0;
   }

   // Toggle items

   &__toggle {
        position: relative;
        cursor: pointer;
        padding:  $global-margin 0;

        &:hover {

                .c-search-filters__toggle-label {
                    color: $primary-color;
                }

                .c-search-filters__toggle-icon {
                    fill: $primary-color;
                }

                .c-search-filters__toggle-arrow {
                    fill: $primary-color;
                }
        }

        @include breakpoint(medium) {
            margin: 0 $global-margin $global-margin 0;
            padding: 0;
        }

   }

   &__toggle-label {
       display: inline-block;
       padding: rem(0 80 0 10);
       transition: color .3s ease-in-out;
   }

   &__toggle-icon {
       width: rem(30);
       height: rem(30);
       vertical-align: middle;
       fill: $black;
       transition: fill .3s ease-in-out;
   }


   &__toggle-arrow {
       position: absolute;
       right: 0;
       top: 35%;
       width: rem(20);
       height: rem(20);
       //transform: translateY( rem(2) );
       vertical-align: middle;
       fill: $black;
       transition: fill .3s ease-in-out;

       &.is-active {
         transform: rotate(180deg);// This rotates the icon to the active state
       }

       @include breakpoint(medium) {
           position: relative;
           top: inherit;
           right: inherit;
       }
   }


   // Search area and saison select areas

   &__search-input {
       display: flex;
       flex-wrap: wrap;
       align-items: center;
       background-color: $white;
       box-shadow: $global-shadow;
       margin-bottom: $global-margin;
       padding: rem(8);

       @include breakpoint(medium) {
         flex-wrap: nowrap;
       }

   }

   .c-searchfield__wrapper {
       flex: 1 0 auto;

       @include breakpoint(medium) {
           flex: 0 1 70%;

           &:after { // show divider line starting on medium screens
                content: '';
                width: rem(1);
                border-right: rem(1) solid $dark-gray;
                margin-right: $global-margin;
            }
       }

       &--no-divider {
           &:after {
               border:none;
               width: 0;
               margin-right: 0;
           }
       }
   }

   .c-searchfield__wrapper--full-width {
        flex: 0 1 100%;

        &:after {
            border: none;
            width: 0;
            margin-right: 0;
        }
   }

   &__season-select { // used in the search field on the spielbetrieb page

       flex: 1 0 auto;
       margin: 0;
       border: none;
       border-top: rem(1) solid $black;
       padding-top: rem(15);

       &:focus {
           margin: 0;
       }

       @include breakpoint(medium) {
            flex: 0 1 30%;
            border: none;
            padding-top: 0;
       }

   }

   &__filter-container {
        max-width: rem(970);
        overflow: hidden;
        transition: max-height .6s ease-in-out;

        &.close {
            max-height: 0 !important;
         }
   }

}
