/* ==========================================================================
   Club Officials List
   ========================================================================== */

/**
 * c-club-officials
 * This styles list generated on the Verein Page.
 */

.c-club-officials{
    columns: 2;
    list-style-type: none;
    margin: 0 0 $global-margin 0;

    &__item {
        break-inside: avoid;
        margin-bottom: $global-margin;
    }

    &__role {
        font-weight: 400;
        font-size: rem(13);
        display: block;
        margin-bottom: .3rem;
     }

     &__name {
         font-weight: $global-weight-bold;
     }
}
