/* ==========================================================================
   Search Category Buttons
   ========================================================================== */

/**
 * c-search-cat-button
 * A button that is used in the header and mobile header in conjunction with the
 * the full text search field.
 */

.c-search-cat-button {

    background-color: $primary-light-4;
    padding: rem(15 10);
    display: block;
    width: 100%;
    border-radius: 0;
    text-align: left;
    border-bottom: rem(1) solid $light-gray;
    cursor: pointer;

    &__icon {
        width: rem(14);
        height: rem(14);
        fill: $dark-gray;
        margin-right: rem(5);
    }

    &__text--large {
        text-transform: uppercase;
        font-weight: $header-font-weight;
        font-family: $header-font-family;
        letter-spacing: rem(1);
    }

    &__text--small {
        font-size: rem(15);
        color: $dark-gray;
    }
     
}