/* ==========================================================================
   Navigation Component
   ========================================================================== */

/**
 * c-nav
 * The site main navigation
 * Hover color exceptions are handled in the layouts/page-header.scss.
 * focus-within data attribute for use with polyfill https://github.com/jonathantneal/focus-within
 */

.c-nav {

    &__list {
        display: flex;
        justify-content: center;
        margin: 0;
        list-style-type: none;

        @media screen and (min-width: rem($header-medium-breakpoint)) {
            justify-content: flex-end;
        }
    }

    &__item {
        flex: 0 1 auto;

        &.is-parent {

            &:hover {

                > .c-megamenu {
                    visibility: visible;
                    opacity: 1;
                    height: auto;
                    animation: megafadein 1s ease-in-out;
                }

                .c-nav__link {

                    &:after { // Triangle effect
                        content: '';
                        animation: megafadein 1s ease-in-out;
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        height: 0;
                        left: calc(50% - 12px); // centers the triangle
                        border-left: rem(12) solid transparent;
                        border-right: rem(12) solid transparent;
                        border-bottom: rem(12) solid $primary-light-3; // same color as megamenu
                    }

                    .u-menu-hitarea { // this extends the mouse hover hit area, so that the user has better chance to move to the megamenu
                        position: absolute;
                        height: rem(50);
                        bottom: 0;
                        width: 160%;
                        border-bottom: solid rem(30) transparent;
                        border-left: solid rem(30) transparent;
                        border-right: solid rem(30) transparent;
                        left: calc(80% + 15px);
                        right: 0;
                        transform: translateX(-80%);
                    }

                }
            }

        }

    }

    &__link {
        display: flex;
        align-items: center;
        padding: rem(20 10);
        margin: rem(0 10);
        position: relative;

        @media screen and (min-width: rem($header-medium-breakpoint)) {
            height: $header-height;
        }

        &.active {
            font-weight: $header-font-weight;
        }

    }

    &__link-text {
        flex: 1 1 auto;
    }

}

.c-sub-nav {
    list-style-type: none;
    margin: 0 0 $global-margin 0;
    columns: rem(200) 2;

    &__item {
        margin-bottom: rem(20);
    }

    &__link {
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        color: $body-font-color;
        text-transform: uppercase;
        font-size: rem(14);
    }

    @media all and (min-width: rem(1025)) {
        .c-sub-nav__item {
            margin-bottom: rem(40);
        }

        .c-sub-nav__link {
            font-size: rem(16);
        }
    }
}
