/* ==========================================================================
   Scroll to top button
   ========================================================================== */

/**
 * c-scroll-up
 */

 .c-scroll-up {

     position: fixed;
     bottom: rem(20);
     z-index: 60;
     left: 50%;
     transform: translateX(-50%);
     transition: opacity .6s ease-in-out;

     @include breakpoint(large) {
        visibility: hidden;
        opacity: 0;
     }

     &__button {
         background-color: $primary-light-2;
         border-radius: 50%;
         padding: rem(8);
         margin: auto;
         box-shadow: $global-shadow;
         transition: background .3s ease-in-out;

         &:hover {
             background-color: $primary-color;
             border-radius: 50%;
         }

     }

     &__link {
        background-color: get-color('primary');
        border-radius: 50%;
        height: rem(50);
        width: rem(50);
        display: flex;
        justify-content: center;
        align-items: center;

        transition: background .3s ease-in-out;

        &:hover {
            background-color: $primary-light-2;
            border-radius: 50%;
        }
     }

     &__icon {
         width: rem(25);
         height: rem(25);
         transform: rotate(-90deg);
         fill: $white;
     }
 }
