/* ==========================================================================
   Gallery styles
   ========================================================================== */

/**
 * c-gallery
 * Styles for the content gallery component
 */


 .c-gallery {
     margin: $global-margin 0;

     .glide__bullets {
         display: flex;
     }

     &--single {

        .glide {

            &__arrow-right, &__arrow-left {
                width: rem(50);
                height: rem(50);
                fill: $white;
            }
         }

     }

     &--multi {
        @include breakpoint(large) {
            .glide__bullets {
                display: none;
            }
        }
     }
 }
