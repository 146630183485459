/* ==========================================================================
   #ATOM STYLES
   ========================================================================== */

/**
 * Atom Styles
 *
 * Used to add the following atomic styles:
 *
 * 1. margin styles
 * 2. padding styles
 * 3. js, no-js
 * 4. text color
 * 5. background color
 */

.u-no-margin {
    margin: 0 !important;
}
.u-no-margin--top {
    margin-top: 0 !important;
}
.u-no-margin--bottom {
    margin-bottom: 0 !important;
}

.u-no-padding {
    padding: 0 !important;
}
.u-no-padding--top {
    padding-top: 0 !important;
}
.u-no-padding--bottom {
    padding-bottom: 0 !important;
}

@each $name, $size in $extra-class-sizes {
    .u-extra-#{$name}-margin--top {
        margin-top: $size !important;
    }
    .u-extra-#{$name}-margin--bottom {
        margin-bottom: $size !important;
    }
    .u-extra-#{$name}-padding--top {
        padding-top: $size !important;
    }
    .u-extra-#{$name}-padding--bottom {
        padding-bottom: $size !important;
    }
}


html.no-js {
    .u-show-with-js {
        display: none !important;
    }
}
html.js {
    .u-hide-with-js {
        display: none !important;
    }
}

@each $name, $color in map-merge($foundation-palette, (white: #fff)) {
    .u-color--#{$name} {
        color: $color !important;
        fill: $color !important;
    }
}

@each $name, $color in map-merge($foundation-palette, (light: $light-gray)) {
    .u-background--#{$name} {
        background: $color;

        > *, > div > * {
            color: color-pick-contrast($color, ($white, $body-font-color), 0.6);
            fill: color-pick-contrast($color, ($white, $body-font-color), 0.6);

            @if $name == 'dark' {
                .c-lead p {
                    color: $white;
                }
            }
        }
    }
}

.u-no-shadow {
    box-shadow: none !important;
}

.u-label-block {
    display: block;
    position: relative;
    margin-top: rem(10);
}

.u-label-flex {
    display: flex;
    position: relative;
    margin-top: rem(10);
}

.u-checkbox {
    z-index: 2;
    width: rem(20);
    height: rem(20);
    display: inline-block;
    background-color: $white;
    border: rem(1) solid $dark-gray;
    position: absolute;
    top: 2px;
    left: 0;
    transition: background-color .03s ease-in-out;
    cursor: pointer;
    box-shadow: 0 0 0 4px $white;

    ~ small {
        cursor: pointer; // used on the footer reg form
        margin-left: rem(5);
    }

}

input[type="checkbox"]:checked ~ span.u-checkbox {
    background-color: $white;
    background-size: rem(16) rem(16);
    background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="noun_1780646_cc" fill="#666666"><polygon id="Shape" points="14.997 32.6956 0.99728 45.8307 31.4877 87.694 90.9992 13.2639 77.8156 0.30596 31.4877 57.4269"></polygon></g></svg>');
    background-repeat: no-repeat;
    background-position: center bottom;
}


.u-error {
    color: get-color(alert);
    border: rem(2) solid get-color(alert);
    border-radius: $global-radius;
    padding: rem(16 20);

    &--inline {
        background-color: get-color(alert);
        color: $white;
        display: inline-block;
        width: 100%;
        padding: rem(5);
        line-height: 1;
        font-size: $small-font-size;
        margin-bottom: rem(15);
    }

    &--label-wrapper {
        input {
            border-color: get-color(alert);
            margin-bottom: 0;

            &::placeholder {
                color: get-color(alert);
            }
        }
    }
}

.u-success {
    color: get-color(success);
    border: rem(2) solid get-color(success);
    border-radius: $global-radius;
    padding: rem(16 20);
}

input.u-progress-empty {
    color: get-color('warning');
    border-bottom: rem(2) solid get-color('warning');

    &::placeholder {
        color: get-color('warning');
    }
}

textarea.u-progress-empty {
    border: rem(2) solid get-color('warning');
}

// - container element must have position: relative
.u-overlay-link {
    position: absolute;
    z-index: 50;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/*
* Typography Helpers
* TODO post processor removes box-orient. Needs to be fixed.
*/

.u-line-clamp {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
}
