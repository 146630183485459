/* ==========================================================================
   Preloader graphic
   ========================================================================== */

/**
 * c-preloader
 *
 */

.c-preloader {
    &__image {
        width: rem(50);
        height: rem(50);
    }

    &-hide {
        display: none;
    }
}
