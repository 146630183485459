/* ==========================================================================
   Top Ranking list
   ========================================================================== */

/**
 * c-top-rankings
 * Styling for top 5 listing component
 */

.c-top-rankings {
    margin: $global-margin auto;
    max-width: rem(1170);

    &__list {
        display: flex;
        flex-wrap: nowrap;
        padding: rem(10 10 50 10);
        -webkit-overflow-scrolling: touch; // this forces better ios scrolling
        overflow-x: scroll;
        overflow-y: visible;
    }

    &__item {
        flex: 0 0 auto;
        padding: rem(10);
        margin: rem(10);
        width: 100%;
        max-width: rem(564);
        min-width: rem(320);
    }

    &__content {
        box-shadow: $global-shadow;
    }

    // Header styles

    &__header {
        position: relative;
        min-height: rem(215);
        padding: rem(10 20);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        text-shadow: $text-shadow;
    }

    &__header-bkgd {
        margin: 0;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        &:before {
            @include image-effect-base;

        }

    }

    &__bkgd-image {
        filter: grayscale(100%);
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top center;
        background-image:
        linear-gradient(
            135deg,
            $primary-color, $primary-neon
        ) ;

    }

    // Header Content

    &__header-content {
        position: relative;
        z-index: 2;
        padding: rem(20);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }

    &__header-title {
        margin: 0;
        color: $white;
        text-align: center;
    }



    // Dropdown styles

    &__form {
        max-width: rem(279);
        margin: auto;
    }

    &__dropdowns {
        padding: rem(10 20);
    }

    // Table styles

    &__table {
        margin-bottom: 0;

        tbody tr {
            background-color: $primary-light-4;
        }

    }

}
