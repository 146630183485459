/* ==========================================================================
   Masonry News Grid
   ========================================================================== */

/**
 * c-newsgric
 * Styles for latest news block
 */

.c-newsgrid {
    margin-bottom: $global-margin;

    .c-teaser {
        width: 100%;

        @include breakpoint(medium) {
            display: inline-block;
            width: 50%;
            width: calc(50% - #{map-get($grid-column-gutter, medium)});
        }

        @include breakpoint(large) {
            width: 33.33%;
            width: calc(33.33% - #{map-get($grid-column-gutter, medium)});
        }
    }

    .c-newsgrid__gutter {
        width: map-get($grid-column-gutter, medium);
    }

}
