/* ==========================================================================
   Search Button component
   ========================================================================== */


/**
 * c-search-button
 * Controls styling and placement of search button in the header
 */

 .c-search-button {
    padding: rem(10);
    cursor: pointer;
    transition: background .3s ease-in-out;
    display: none;

    &__icon {
        width: rem(25);
        height: rem(25);
        fill: $black;
        transition: fill .3s ease-in-out;
        display: inline-block;
        vertical-align: middle;
    }

    &__label {
        transition: color .3s ease-in-out;
        vertical-align: middle;
    }

    &:hover {
        background-color: $primary-color;

        .c-search-button__icon, .c-search-button__label {
            fill: $white;
            color: $white;
        }

    }

    @include breakpoint(medium) {
        display: block;
    }

}
