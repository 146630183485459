/* ==========================================================================
   Game Distribution Player Card
   ========================================================================== */

/**
 * c-game-distribution-card
 * This component styles the content of the player card with the game distribution pie chart
 */

.c-game-distribution-card{

    .chartjs-render-monitor {
        width: rem(200) !important;
        height: rem(200) !important;
    }
}
