/* ==========================================================================
   YouTube Video component
   ========================================================================== */


/**
 * c-video-embed
 * Styles used for YouTube Video embedding component
 */

 // https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php

 .c-video-embed {
    position: relative;
	padding-bottom: 56.25%; /* 16:9 */
    margin-bottom: $global-margin;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__overlay {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% - 80px);
        width: rem(80);
        height: rem(80);
        z-index: 3;
        text-align: center;
        margin: auto;

        &:hover {
            .c-video-embed__icon-play {
                fill: red;
            }
        }
    }

    &__icon-play, &__icon-triangle {
        width: rem(80);
        height: rem(80);
        position: absolute;
    }

    &__icon-play {
        fill: $black;
        transition: fill .2s ease-in-out;
    }

    &__cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__image {
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
 }