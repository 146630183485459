/* ==========================================================================
   Card Component
   ========================================================================== */

/**
 * c-card
 * This component is a content wrapper that gives the card styling
 */

.c-card{
    background-color: $white;
    padding: rem(30);
    margin: $global-margin 0;
    box-shadow: $global-shadow;
}


