/* ==========================================================================
   Chart circle
   ========================================================================== */

/**
 * c-chart-circle
 * The chart circle element as seen in a player card on the player details page
 */


.c-chart-circle {
    position: relative;
    z-index: 1;
    width: rem(95);
    height: rem(95);
    text-align: center;
    margin: rem(10) auto $global-margin;

    &__stats {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        z-index: 10;
        font-size: rem(16);
        font-weight: $global-weight-bold;
        font-family: $header-font-family;
    }

    &__circle {
        border: rem(1) solid $black;
        width: rem(80);
        height: rem(80);
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        margin: auto;
    }

    &__canvas {
        position: relative;
        z-index: 5;
    }
}
