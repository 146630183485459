/* ==========================================================================
   Download Card Component
   ========================================================================== */

/**
 * c-download-card
 * This component styles the content of the download card
 */

.c-download-card {
    background-color: $primary-light-4;
    padding: rem(15);

    &__heading {
        color: $primary-color;
        font-weight: $header-font-weight;
        font-size: rem(20);
        margin-bottom: rem(20);
    }

    &__link {
        font-weight: $global-weight-bold;

        &:hover {

            .c-download-card__link-icon {
                fill: $primary-color;
            }
        }
    }


    &__media {
        background-color: $white;
        text-align: center;
        margin-bottom: $global-margin;
        min-height: rem(135);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        width: rem(80);
        height: rem(80);
        fill: $body-font-color;
    }

    &__link-icon {
        width: rem(22);
        height: rem(22);
        fill: $body-font-color;
        display: inline-block;
        vertical-align: middle;
        margin-right: rem(10);
        transition: fill .3s ease-in-out;

    }

    &__person-title {
        font-size: $small-font-size;
        color: $black;
    }

}


/* Variations
   ========================================================================== */

   // list item version

.c-download-card{

    &--list-item {
        background-color: $white;
        display: flex;
        padding: rem(20);
        align-items: center;
        margin: rem(10 0);

        .c-download-card__icon {
            flex: 0 1 auto;
            width: rem(30);
            height: rem(30);
        }

        .c-download-card__heading {
            margin-bottom: 0;
        }

        .c-download-card__content {
            flex: 1 1 auto;
            margin-left: $global-margin;
        }

        .c-download-card__link {
            color: $body-font-color;
            font-weight: $global-weight-normal;

            &:hover {
                color: $primary-color;
            }

        }

    }

}
