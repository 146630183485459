.u-swipeable-table {
    overflow-x: auto;
    > table {
        width: 100%;
        min-width: max-content;
        empty-cells: show;
        @include breakpoint(xlarge) {
            min-width: auto;
        }
    }
}
