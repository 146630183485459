@charset "UTF-8";
// http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
    width: device-width;
}

// import modnern-normalize (https://github.com/sindresorhus/modern-normalize)
@import 'node_modules/modern-normalize/modern-normalize';

// import 3rd party components
@import '../../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss';

// import foundation settins and scss
@import '../../node_modules/foundation-sites/scss/util/util';
@import 'functions';
@import 'settings';
@import '../../node_modules/foundation-sites/scss/foundation';

// Foundation Feature Includes
@include foundation-global-styles;
@include foundation-xy-grid-classes(
    // include only those you need...
    $base-grid: true,
    $margin-grid: true,
    $padding-grid: true,
    $block-grid: false,
    $collapse: false,
    $offset: true,
    $vertical-grid: false,
    $frame-grid: false
);
@include foundation-typography;
@include foundation-forms;
//@include foundation-button;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;

// custom Mixins
@import 'mixins';

// Local Imports
@import 'lazyload';
@import 'layout';
@import 'animations';
@import 'typography';
@import 'forms';
@import 'tables';
@import 'utils';

// Components
@import 'components/logo';
@import 'components/tvm-logo';
@import 'components/search-button';
@import 'components/nav';
@import 'components/mobile-button';
@import 'components/partner-logos';
@import 'components/company-sign';
@import 'components/newsletterform';
@import 'components/footer-nav';
@import 'components/social-media';
@import 'components/header-banner';
@import 'components/teaser';
@import 'components/teaser-banner';
@import 'components/card';
@import 'components/contact-card';
@import 'components/lead';
@import 'components/hero';
@import 'components/megamenu';
@import 'components/breadcrumb-link';
@import 'components/db-search-field';
@import 'components/sidebanner';
@import 'components/slider';
@import 'components/mobile-nav';
@import 'components/search-field';
@import 'components/slide-toggler';
@import 'components/buttons';
@import 'components/search-cat-button';
@import 'components/header-search';
@import 'components/sharing';
@import 'components/courses';
@import 'components/calendar-event';
@import 'components/districts';
@import "components/swipeable-table";
@import 'components/tables-tournament';
@import 'components/newsgrid';
@import 'components/accordion';
@import 'components/logo-district';
@import 'components/download-card';
@import 'components/calendar-download';
@import 'components/search-tab-nav';
@import 'components/search-filters';
@import 'components/search-results';
@import 'components/input-zipcode';
@import 'components/chart-bar';
@import 'components/chart-circle';
@import 'components/chart-doughnut';
@import 'components/videoembed';
@import 'components/tvmservice';
@import 'components/heading';
@import 'components/box-card';
@import 'components/top-rankings';
@import 'components/gallery';
@import 'components/swipebox';
@import 'components/newsfilters';
@import 'components/map';
@import 'components/preloader';
@import 'components/news-header';
@import 'components/scroll-up';
@import 'components/legend';
@import 'components/club-officials-list';
@import 'components/club-detail';
@import 'components/taglist';
@import 'components/cookieconsent';
@import 'components/ranking-icon';
@import 'components/club-nav';

// Dashboard Cards
@import 'components/game-distribution-card';
@import 'components/player-card';
@import 'components/comparison-card';

//Layouts
@import 'layouts/page-header';
@import 'layouts/page-footer';
@import 'layouts/card-list';
@import 'layouts/breadcrumbs';
@import 'layouts/boxes-list';
@import 'layouts/adverts';

// Overrides - should be loaded last
@import 'dashboard';
@import 'print';
@import 'atoms';
