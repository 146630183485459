/* ==========================================================================
   Accordion
   ========================================================================== */

/**
 * c-accordion
 * Accordion styles that extend the Foundation accordion settings
 */

 .c-accordion {
     margin-bottom: 0;

    &__district-icon {
        width: rem(58);
        height: rem(70);
    }

    &__link-text {
        display: flex;
        width: 100%;
        align-items: center;
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        color: $body-font-color;

        .has-teasertext {
            justify-content: space-between;
        }
    }

    &__link-label {
        display: block;
    }

    &__link-teasertext {
        display: none;
        font-family: $body-font-family;
        font-size: $body-font-size;
        font-weight: normal;
        margin: 0 rem(50);
        max-width: rem(650);

        @include breakpoint(large) {
            display: block;
        }
    }

    &__title-content {
        max-width: $global-width;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__link-visual {
        flex: 0 1 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include breakpoint(large) {
            flex-basis: rem(150);
        }
    }

    &__link-visual-text::after {
        font-size: rem(16);
        display: none;
        font-weight: $header-font-weight;
        content: 'mehr zeigen';
        flex: 0 1 rem(50);

        @include breakpoint(medium) {
            display: inline-block
        }
    }

    &__link-visual-icon::after {
        flex: 0 0 auto;
        display: inline-block;
        font-size: rem(30);
        color: $body-font-color;
        font-family: $body-font-family;
        font-weight: 300;
        content: '+';
        margin-left: rem(10);

        @include breakpoint(medium) {
            font-size: rem(50);
        }
    }

    // adjust size of shield logos
    &__district-icon {
        width: rem(73);
        height: rem(80);
    }

 }

// Foundation specific styles

.accordion-title {
    transition: background .2s ease-in-out;

    @include breakpoint(medium) {
        font-size: rem(20);
    }

    @include breakpoint(large) {
        font-size: rem(30);
    }
}

 .accordion-item {
     border-bottom: 2px solid $medium-gray;

    &.is-active {
        background-color: $primary-light-4;

        .c-accordion__link-visual-icon::after {
            content: 'x';
        }
        .c-accordion__link-visual-text::after {
            content: 'verbergen';
        }
    }
 }
