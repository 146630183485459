/* ==========================================================================
   Teaser component
   ========================================================================== */


/**
 * c-teaser
 * Teaser styles used for news and content teasers
 */

 .c-teaser {
    // Block settings
    margin-bottom: $global-margin;
    box-shadow: $global-shadow;

    // Element settings
    &__media {
        margin-bottom: 0;
        position: relative;

        &:before {
            @include image-effect-base;

        }
    }

    &__image {
        width: 100%;
        filter: grayscale(100%);
    }

    &__content {
        padding: rem(15);
        position: relative; // needed for overlay link
    }

    &__meta {
        margin-bottom: rem(15);
        font-size: rem(12);
        position: relative;
        z-index: 5;
    }

    &__date {
        margin-right: rem(10);
        line-height: 1;
    }

    &__tags{
        color: $primary-color;
        text-transform: uppercase;
        line-height: 1;
        font-weight: $header-font-weight;
    }

    &__heading {
        font-size: rem(18);
        margin-bottom: rem(10);
        transition: color .3s ease-in-out;
        line-height: $typogrid*2.5;
        margin-top: 0;
        @include breakpoint(medium) {
            font-size: rem(20);
        }
    }

    &__icon {
        width: rem(40);
        height: rem(40);
        fill: $primary-color;
        transition: fill .3s ease-in-out;
    }

    &:hover {
        .c-teaser__icon {
            fill: $black;
        }

        .c-teaser__heading {
            color: $primary-color;
        }

        .c-button {
            border-color: $black;
        }

        .c-button__label {
            color: $black;
        }
    }


 }



/* Variations
   ========================================================================== */



//--------- Miniature variation

.c-teaser--miniature {
    display: flex;
    padding: rem(15);
    align-items: flex-start;
    flex-wrap: wrap;

    .c-teaser {
        // hide icon
        &__icon {
            display: none;
        }

        &__media {
            flex: 0 0 30%;
        }

        &__image-wrapper {
            padding-top: 56.25%; // 16 to 9 aspect ration
            position: relative;

        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;

        }

        &__content {
            flex: 1 1 70%;
            padding: rem(0 0 0 20);
        }

        &__heading {
            font-size: $body-font-size;
            margin-bottom: 0;
            line-height: $typogrid*2;
        }

    }


}

//--------- News variation

.c-teaser--news {
    background-color: $white;
}

//--------- Static variation

.c-teaser--static {

    .c-teaser {

        &__content {
            padding: rem(20 0 0 );
        }

    }

}

//--------- Landscape variation


.c-teaser--landscape {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .c-teaser {

        &__media {
            flex: 0 0 100%;

            @include breakpoint(medium) {
                flex: 1 0 25%;
            }
        }

        &__image-wrapper {
            padding-top: 56.25%; // 16 to 9 aspect ration
            position: relative;
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;

        }

        &__content {
            flex: 1 1 auto;
            padding: rem(20 0 0);

            @include breakpoint(medium) {
                flex: 1 1 55%;
                padding: rem(0 0 0 20);
            }
        }

    }


}


//--------- Megamenu News

.c-teaser--megamenu--news {
    margin-bottom: rem(1);
    background-color: $white;
}



//--------- Megamenu Default

.c-teaser--megamenu--default {
    margin-bottom: rem(1);
    background-color: $white;

    .c-teaser__meta {
        display: none;
    }

    .c-teaser__icon {
        display: block;
    }
}

//--------- Search variation

.c-teaser--search {

    .c-teaser {

        &__icon {
            display: block;
        }

        @include breakpoint(small only) {

            &__media {
                flex: 1 1 100%;
            }

            &__content {
                flex: 1 0 100%;
                margin-top: $global-margin;
                padding-left: 0;
            }
        }

    }
}

//--------- Dashboard varation

.c-teaser--dashboard {
    box-shadow: none;
    padding: 0;
    margin-bottom: rem(15);
    position: relative;

    .c-teaser__icon {
        display: inline-block;
        width: rem(30);
        height: rem(30);
    }

    .c-teaser__content {
        position: initial;
    }

    &:hover {
        .c-teaser__icon {
            fill: $white;
        }

        .c-teaser__heading {
            color: $white;
        }
    }
}


//--------------- Advertising
.c-teaser--advert {
    figure {
        margin: 0;
    }

    img {
        width: 100%;
    }
}

//--------- Color variations based on html district class

@each $name, $colors in $district-colors {

    // class gets set on the html tag
    .is-#{$name} {

        // teasers are also shown in the header. Here we do not want the color of the club,
        // therefore we target teasers inside of the l-page-content area.

          .l-page-content .c-teaser__media:before {
            @include district-background-color($colors);
        }
    }

     //-- Top news teaser - set background
     .c-teaser--topnews.c-teaser-area--#{$name} {

        @include district-background-color($colors);

    }

    .c-teaser-area--#{$name} {

        .c-teaser__tags {
            color: map-get($colors, 'dark');
        }

        .c-teaser__icon {
            fill: map-get($colors, 'dark');
        }

        .c-teaser__media:before {

            @include district-background-color($colors);

        }

        &:hover {
            .c-teaser__heading {
                color: map-get($colors, 'dark');
            }
        }

    }
}



//--------- Top News

.c-teaser--topnews {
    color: $white;

    .c-teaser__icon {
        fill: $white;
    }

    .c-teaser__tags {
        color: $white;
    }

    &:hover {

        .c-teaser__icon {
            fill: $black;
        }

        .c-teaser__heading {
            color: $black;
        }
    }
}



//--------- Area TVM - teasers from the TVM news area. All have same colored background

.c-teaser--topnews.c-teaser-area--tvm {
    background-image:
    linear-gradient(
        135deg,
        $primary-color, $primary-neon
    );

}



//--------- Download Teaser

.c-teaser--download {

    .c-teaser__heading {
        font-size: rem(30);
    }

}
