/* ==========================================================================
   Button
   ========================================================================== */

/**
 * base styles
 */


 .c-button {
    @include button;
    width: auto;
    font-weight: $global-weight-bold;
    line-height: $typogrid;
    position: relative;
    overflow: hidden; // required for the rounded edges of the button to appear when we have an icon with background color
    margin: 0;
    border: none;
    text-transform: uppercase;

    &__label {
        display: inline-block;
        transition: color .3s ease-in-out;
        letter-spacing: rem(2);
        line-height: 1.8;
    }

    &__icon-background {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: em(44);
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: em(22);
        height: em(22);
        fill: $white;
        transition: fill .3s ease-in-out;
    }

    /* Variations
        ========================================================================== */

        &--icon {
            text-align: left;
        }

        &--icon &__label {
            padding-left: unitless-calc(get-side($button-padding, left)) + em(22);
        }

        &--icon.c-button--icon-with-background &__label {
            padding-left: unitless-calc(get-side($button-padding, left)) + em(44);
        }

        &--medium {
            font-size: rem(15);

            .c-button__label {
                padding-top: rem(get-side($button-padding, top) * 0.9);
                padding-bottom: rem(get-side($button-padding, bottom) * 0.9);
            }
        }

        &--small {
            font-size: rem(12);

            .c-button__label {
                padding-top: rem(get-side($button-padding, top) * 0.7);
                padding-bottom: rem(get-side($button-padding, bottom) * 0.7);
            }
        }

    // full width buttons
    &--fullwidth { @include button-expand; }
        padding-top: rem(15);
        padding-bottom: rem(15);

      // Colors
      @each $name, $styles in $button-styles {

            &--#{$name} {
                @include button-style(map-get($styles, 'background'), map-get($styles, 'background-hover'), map-get($styles, 'text-color'));
                svg {
                    fill: map-get($styles, 'text-color');
                }
            }
      }

      // Hollow style
      &--outline, &--transparent {
            @include button-hollow;

            box-shadow: none;
            border: $button-hollow-border-width solid map-get(map-get($button-styles, 'primary'), 'border-color');
            color: map-get(map-get($button-styles, 'primary'), 'border-color');
            transition: border .3s ease-in-out;

            @each $name, $styles in $button-styles {

                &.c-button--#{$name} {

                      border: $button-hollow-border-width solid map-get($styles, 'border-color');
                      color: map-get($styles, 'border-color');

                      &:hover, &:focus {

                          border-color: darken(map-get($styles, 'border-color'), 5%);
                      }

                      .c-button--icon-with-background .c-button__icon-background {
                          border-right: $button-hollow-border-width solid map-get($styles, 'border-color');
                      }

                      .c-button__icon {
                          fill: map-get($styles, 'border-color');
                      }
                }

                &:hover .c-button__label, &:focus .c-button__label {
                    color: darken(map-get($styles, 'hover-text-color'), 5%);
                }
            }
      }

      //-- outline color variations
      &--outline--white {
            border-color: $white;
            color: $white;

            &:hover {
                border-color: $secondary-color;

                .c-button__label {
                    color: $secondary-color;
                }
            }
      }

      &--outline--primary {
        border-color: $primary-color;
        color: $primary-color;


        .c-button__icon {
            fill: $primary-color
        }

        &:hover {
            border-color: $black;

            .c-button__label {
                color: $black;
            }

            .c-button__icon {
                fill: $black;
            }
        }
  }

      &--transparent {
          background: transparent !important;
          border: none !important;
          margin-bottom: rem(-5); // this is helpful when used as last button in a modal. this might need refactoring with other usecases
          font-weight: $global-weight-normal;

          .c-button__label {
              padding: 0;
          }
      }


      &--white {
            background-color: $white;
            transition: background-color 0.3s ease-in-out;
            color: $dark-gray;
            border: none;

            .c-button__icon {
                fill: $black;
            }

            &:hover {
                background-color: transparent;

                .c-button__label {
                    color: $primary-color;
                }
            }
      }

      &--primary {

            .c-button__icon-background {
                  background-color: darken($primary-color,10%);
            }

            .c-button__icon {
                  fill: $white;
            }


      }
}


//--------- Color variations based on html district class

@each $name, $colors in $district-colors {

    // class gets set on the html tag - we also only want to affect buttons with the content section
    .is-#{$name} .l-page-content {

        .c-button--outline {
            border-color: map-get($colors, 'dark');
        }

        .c-button__label {
            color: map-get($colors, 'dark');
        }
    }
}
