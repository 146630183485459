/* ==========================================================================
   Megamenu Navigation Component
   ========================================================================== */

/**
 * c-megamenu
 * The menu that opens underneath the main navigation
 */

.c-megamenu {
   background-color: $primary-light-3;
   box-shadow: rem(0 5 30 -10) $global-shadow-color;
   top: calc(#{$header-height} + 34px); // add header-height to height of nav on medium screens
   left: 0;
   right: 0;
   position: absolute;
   z-index: 50;
   width: 100%;
   visibility: hidden;
   opacity: 0;
   height: 0;
   padding: rem(60 0);

   @media screen and (min-width: rem($header-medium-breakpoint)) {
        top: $header-height;
   }

   &__bottom {
       background-color: $white;
       padding-top: $global-margin;
       margin-top: $global-margin;
   }

   &__heading {
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        color: $body-font-color;
        text-transform: uppercase;
   }

   &__downloads {
        max-width: 80%;
        box-shadow: $global-shadow;
   }


   &__download-link {
        display: flex;
        align-items: flex-start;
        background-color: $white;
        padding: rem(5 10);
        margin-bottom: rem(1);
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        transition: background .3s ease-in-out;

        &:hover {
            background-color: lighten($light-gray, 10%);
        }
   }

   &__download-linktext {
        flex: 1 1 auto;
    }

   &__download-icon {
        flex: 0 0 rem(15);
        width: rem(15);
        height: rem(15);
        fill: $black;
        transform: translateY(5px);
        margin-right: rem(10);
        display: block;

   }

}

