/* ==========================================================================
   Table components
   ========================================================================== */

/**
 * c-tournament-table
 * Styling for tournament tables
 */

 .c-tournament-table {
    td {
        color: $body-font-color;
    }
}
