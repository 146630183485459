/* ==========================================================================
   News header area - time and categories
   ========================================================================== */

/**
 * c-news-header
 */

.c-news-header {
    &__categories {
        margin-left: rem(15);
        color: $primary-color;
    }
}
