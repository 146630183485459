/* ==========================================================================
   Input zipcode
   ========================================================================== */

/**
 * c-input-zipcode
 * The stylings for the form component showing zipcode input
 */

.c-input-zipcode {
    display: flex;

    &__icon-wrapper {
        border: rem(1) solid $black;
        border-left: none;
        padding: rem(6);
    }

    &__icon {
        width: rem(20);
        height: rem(20);
        transform: translateY( rem(5) );
    }

    &__input {
        border: rem(1) solid $black;
        width: 100%;
        padding-left: rem(8);
    }

}

