/* ==========================================================================
   Calendar download link
   ========================================================================== */

/**
 * c-calendar-download
 * This calendar download link can be found on an event detail page
 */

.c-calendar-download {
    margin: $global-margin 0;

    &__icon {
        width: rem(20);
        height: rem(20);
        fill: $black;
        display: inline;
        vertical-align: bottom;
        margin-right: rem(10);
    }

    &__link {
        font-weight: $header-font-weight;
    }

    @include breakpoint(large) {
        margin-bottom: $global-margin *2;
    }

}
