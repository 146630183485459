/* ==========================================================================
   Advertising grid layout
   ========================================================================== */
.l-advert-grid {
    display: flex;
    justify-content: space-between;

    figure {
        flex: 0 1 33%;
        padding: 1%;
    }
}
