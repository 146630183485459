/* ==========================================================================
   Slider
   ========================================================================== */

/**
 * c-slider
 *
 */

 .c-slider {
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    &__item {
        position: relative;
    }
}


.glide {

    &__slides {
        z-index: 1;
        margin: 0;
    }

    &__icon {
        width: rem(60);
        height: rem(60);
        fill: $primary-color;
        position: relative;
        top: rem(-30); // centers vertical position
        transition: fill .3s ease-in-out;

        &:hover {
            fill: get-color(secondary);
        }
    }

    // controls

    &__controls {
        position: absolute;
        max-width: $global-width;
        margin: auto;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        z-index: 5;
        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }

    &__button {
        position: absolute;
        outline: none;
        top: 50%;
        transform: translateY(-50%);

        &:focus, &:hover {
            svg {
                fill: get-color(secondary);
            }
        }

    }

    &__button--right {
        right: rem(16);
    }

    &__button--left {
        left: rem(16);
    }

    &__arrow-right, &__arrow-left {
        width: rem(30);
        height: rem(30);
        fill: $white;
    }

    &__arrow-left {
        transform: rotate(180deg);
    }

     // bullets
     &__bullets {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $global-margin 0;
    }

    &__bullet {
        width: rem(20);
        height: rem(20);
        display: block;
        background-color: $light-gray;
        border-radius: 50%;
        margin-right: rem(10);
        transition: background-color .3s ease-in-out;

        &:focus {
            outline-width: 1px;
            outline-color: $white;
        }

        &:last-child {
            margin-right: 0;
        }

        &--active, &:hover {
            background-color: $secondary-color;
        }
    }

}

    // Hero Slider classes

.c-slider {

    &__content-wrapper {
        position: relative;
        z-index: 2;
        max-width: rem(900);
        margin: auto;
        padding: 30% rem(20);

        @include breakpoint(medium) {
            padding: 20% rem(20) 15%;
        }

        @media screen and (min-width: rem($header-medium-breakpoint)) {
            padding: 10% rem(20) 15%;
        }

    }

    &__content {
        text-align: center;

        @include breakpoint(medium) {
            text-align: left;
            border-left: rem(15) solid $primary-color;
            padding-left: rem(35);
        }
    }

    &__heading {
        line-height: 1.4;

        @include fluid-type(320px, 1024px, 24px, 60px);
    }

    &__media {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 1;
        margin: 0;
    }

    &__image {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        object-position: top center;
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    &__icon {
        fill: $primary-color;
        width: rem(44);
        height: rem(44);
    }

}


/* Variations
   ========================================================================== */

.c-slider--hero {

    &:after {
        content: '';
        height: 30%;
        width: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,0) 20%, rgba(0,0,0,.7) 60%, rgba(0,0,0,.9) 100%);
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        right: 0;

        @include breakpoint(medium) {
            height: rem(250);
            background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 60%, rgba(0,0,0,.9) 100%);
        }

        @include breakpoint(large) {
            background: linear-gradient(0deg, rgba(0,0,0,0) 20%, rgba(0,0,0,.6) 60%, rgba(0,0,0,.9) 100%);
        }

    }

    .c-slider__heading {
        text-shadow: $text-shadow;
    }

    .glide {

        &__bullets {
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            bottom: rem(30);
            margin: rem(0 20);
        }

        &__track {
            background-color: $black;
            position: relative;
            height: rem(100); // Animation startpoint - not 0 so that screen readers still get the content
            transition: height .5s ease;
        }

        &__bullet {
            width: rem(15);
            height: rem(15);
            margin-right: rem(20);

            &--active, &:hover {
                background-color: $primary-color;
            }
        }

    }

    flex: 0 0 100%; // gives contents 100% width
}

