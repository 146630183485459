/* ==========================================================================
   Calendar event date
   ========================================================================== */

/**
 * c-event-cal
 * This block is used with the latest-event component. Variation is used on the calendar event detail page.
 */

.c-event-cal {
    background-color: $white;
    text-align: center;
    min-width: rem(75);
    max-width: rem(150);
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    color: $body-font-color;

    &__date {
        display: block;
        line-height: 1.2;
        padding: rem(3 0);

        &:first-child() {
            &:after {
                content: '-';
                display: block;
                height: rem(3);
                position: relative;
                top: rem(-6);
            }
        }
    }

    &__year {
        background-color: darken($medium-gray,5%);
        color: $white;
        display: block;
        font-size: $small-font-size;
    }

}


/* Variations
   ========================================================================== */

.c-event-cal--detail {
    max-width: rem(75);
    box-shadow: $global-shadow;
    margin-bottom: $global-margin;

    .c-event-cal {

        &__date {
            padding-top: rem(8);
            padding-bottom: rem(5);

            &:first-child() {
                &:after {
                    content: '';
                }
            }

        }
    }

    @include breakpoint(large) {
        position: relative;
        top: rem(8); // line up box with top of headline

    }

}

.c-event-cal {

    &__long-month {

        font-size: rem(12);
    }

    &__date--detail {
        font-size: rem(28);
        line-height: 0.6;
    }
}

// This variation increases font sizes and is used on the tournament detail page
.c-event-cal--large {

    .c-event-cal__date {
        font-size: rem(30);

        &:first-child() {

            &:after {
                top: rem(-15);
            }
        }
    }

    .c-event-cal__year {
        font-size: rem(25);
    }

}
