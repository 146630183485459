/* ==========================================================================
   Full width teaser component
   ========================================================================== */


/**
 * c-teaser-banner
 * Styles for the hero style teaser image
 */

 .c-teaser-banner {
    position: relative;
    z-index: 1;
    color: $white;
    text-align: center;
    margin-bottom: $global-margin;

    &__media {
        margin: 0;
        position: relative;

        &:before {
            @include image-effect-base;

        }
    }

    &__image {
        filter: grayscale(100%);
    }

    &__content-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        max-width: rem(810);
        padding: rem(20);
        text-shadow: $text-shadow;
    }

    &__heading, &__text {
        text-shadow: $text-shadow;
    }

    &__text {
        font-family: $lead-font-family;
        font-weight: $lead-font-weight;
        color: $lead-font-color;
        font-size: $body-font-size;

        @include breakpoint(medium) {
            font-size: $lead-font-size;
        }
    }

}

//--------- Color variations based on html district class

@each $name, $colors in $district-colors {

    // class gets set on the html tag
    .is-#{$name} {

       .c-teaser-banner__media:before {
           @include district-background-color($colors);
        }
    }

}
