/* ==========================================================================
   Breadcrumb Link Styling
   ========================================================================== */

/**
 * l-breadcrumb-link
 * Styling the links in the breadcrumb
 */

 .c-breadcrumbs-link {
    text-transform: uppercase;

    &.is-current {
        color: $body-font-color;
        text-transform: none;

        &:hover {
            color: $primary-color;
        }
    }

 }
