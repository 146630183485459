/* ==========================================================================
   Search field
   ========================================================================== */

/**
 * c-searchfield
 * search field component with wrapper and icon built in
 */

 .c-searchfield {

    &__wrapper {
        display: flex;
        background-color: $primary-light-3;
        padding: rem(10);
        position: relative;
        box-shadow: $global-shadow;

    }

    &__input {
        background-color: $primary-light-3;
        border: none;
        width: 100%;

        &::placeholder {
            color: $body-font-color;
            font-size: rem(18);
            font-weight: $header-font-weight;
        }
    }

    &__icon {
        width: rem(30);
        height: rem(30);
        fill: $primary-light-4;
        margin-right: rem(10);
    }

    &__required {
        margin-right: rem(10);
    }
 }

 /* Variations
   ========================================================================== */

.c-searchfield--header {
    margin-right: rem(10);
}

.c-searchfield--extra-padding {
    padding: rem(15 5 15 10);
}

.c-searchfield--search-results {

    margin-bottom: $global-margin;

    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}

.c-searchfield--white {

    background-color: $white;

    .c-searchfield__input {
        background-color: $white;

        &::placeholder {
            color: $body-font-color;
        }
    }

    .c-searchfield__icon {
        fill: $black;
    }


}

// the dropdown field in the header
.c-searchfield {

    &__dropdown-wrapper {
        position: relative;
        height: rem(50);
        padding: rem(0 10);
        margin: rem(0 10 0 20);
        background-color: $primary-light-4;
        display: flex;
        align-items: center;
        background-size: rem(20) rem(30);
        background-repeat: no-repeat;
        background-position: 93% 50%;
        background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g transform="matrix(-2.02973e-16,-1.26217,1.05791,-1.70124e-16,4.51006,67.0393)"><path d="M0.1,43L0,43.15L0.28,43.3L25.25,86L27,85L2.43,43L27,1L25.24,0L0.24,42.7L0,42.85L0.1,43Z" style="fill-rule:nonzero;"/></g></svg>');
        transition: background-color .3s ease-in-out;

        &:hover {
            background-color: $primary-light-2;
        }

        @media screen and (min-width: rem($header-medium-breakpoint)) {
            width: rem(276);
        }

    }

    &__dropdown {
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 5;
        display: block;
        height: rem(50);
        cursor: pointer;
    }

    &__dropdown-label {
        flex: 0 0 100%;
        display: block;
        border-bottom: none !important;
        background-color: transparent !important;
        font-size: $small-font-size;

        @media screen and (min-width: rem(780)) {
            font-size: $body-font-size;
        }

    }
}


// Dropdown on mobile device

.c-searchfield__dropdown-wrapper--mobile {
    padding: 0;
    margin: 0;
    border-bottom: rem(1) solid $light-gray;
    background-position: 97% 50%;

    .c-search-cat-button__text {
        font-size: rem(18);
        font-weight: $header-font-weight;
    }
}
