/* ==========================================================================
   Club Subnavigation found on Bezirk pages
   ========================================================================== */

.c-club-nav {
    width: 100%;

    // Adding margin spacing for a specific screen size, the main navigation drops down and is positioned absolute
    @media screen and (min-width: map-get($breakpoints, medium)) and (max-width: rem(1150)) {
        margin-top: rem(34);
    }

    ul {
        list-style: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: rem(10 0);

        @include breakpoint(xlarge) {
            flex-direction: row;
            align-items: center;
            max-width: calc(#{map-get($breakpoints, xxlarge)} - 20px);
            margin: auto;
        }
    }

    // area with the district name and arrow button
    &__district {
        display: flex;
        justify-content: space-between;
        margin: 0 0 0 rem(20);

        @include breakpoint(xlarge) {
            display: block;
            margin: 0 0 0 rem(20);
        }

        button {
            display: flex;
            // Spacing
            position: relative;
            top: rem(5);
            margin-right: rem(20);
            width: rem(70);
            height: rem(32);
            color: $white;

            span {
                margin-right: rem(10);
            }

            svg {
                width: rem(20);
                height: rem(20);
                margin: 0;
                fill: $white;
            }

            @include breakpoint(xlarge) {
                display: none;
            }

            &.is-open {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__list {
        visibility: hidden;
        max-height: 0;
        margin: 0;
        transition: max-height 250ms ease-in-out;

        @include breakpoint(xlarge) {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-end;
            visibility: visible;
            max-height: 100%;
            margin: 0 rem(20) 0;
        }

        &.is-open {
            visibility: visible;
            max-height: rem(1000);
        }
    }

    &__item {
        position: relative;

        @include breakpoint(xlarge) {
            flex: 0 0 auto;
            margin-right: rem(50);
        }

        svg {
            width: rem(20);
            height: rem(20);
            fill: $white;
        }

        &:hover {
            .c-club-nav__submenu {
                visibility: visible;
                opacity: 1;
                width: auto;
                min-height: 100%;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        a {
            display: inline-block;
            padding: rem(8 20);

            @include breakpoint(xlarge) {
                padding: 0;
            }
        }
    }

    &__submenu {
        @include breakpoint(xlarge) {
            position: absolute;
            z-index: 10;
            visibility: hidden;

            ul {
                margin: rem(5) 0 0;
                padding-top: rem(5);
            }

            li {
                margin: 0;
            }

            a {
                padding: rem(10);
                width: 100%;
                display: block;

                &:hover {
                    background-color: rgba(255, 255, 255, .4);
                }
            }
        }
    }

    &__submenu-icon {
        display: inline-block;
        padding: rem(0 5);
        position: relative;
        top: rem(5);
    }
}
