/* ==========================================================================
   #ANIMATIONS
   ========================================================================== */

@keyframes fadein {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to   {
    opacity: 1;
    visibility: visible; }
}

@keyframes fadeout {
  from {
    opacity: 1;
    visibility: visible;
  }
  to   {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes megafadein {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    50% {
        opacity: 0;
        visibility: hidden;
      }

    70%   {
        opacity: 1;
        visibility: visible;
      }

    100%   {
        opacity: 1;
        visibility: visible;
      }
}

  @keyframes fadeout {
    from {
      opacity: 1;
      visibility: visible;
    }
    to   {
      opacity: 0;
      visibility: hidden;
    }
  }


// Classes

.u-fade-in {
  visibility: visible !important;
  opacity:1 !important;
  transition: opacity .6s ease-in-out;
}

.u-slide-up { // used with the top banner
  max-height: 0 !important;
  overflow: hidden !important;
}

.u-fade-out {
  visibility: hidden;
  opacity: 0;
  animation: fadeout .2s linear;
}

.u-fade-start {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.u-translate-0 { // used to animate an item into viewport. Used in the l-header-search layout.
  transform: translateX(0) !important;
}
