/* ==========================================================================
   TVM Service component
   ========================================================================== */

/**
 * Vue
 */

.is-vue-loading .u-hide-for-vue-loading {
    display: none;
}

.u-show-for-vue-mounted {
    display: none;
}
.is-vue-mounted .u-show-for-vue-mounted {
    display: initial;
}