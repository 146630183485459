/* ==========================================================================
   Sharing component
   ========================================================================== */

/**
 * c-sharing
 * Found attached to the sidebanner component
 */

 .c-sharing {
     position: absolute;
     right: 0;
     width: rem(300);
     background-color: $white;
     display: none;
     box-shadow: $global-shadow;

     &__list {
        margin: 0;
        list-style-type: none;
     }

     .c-button__label {
        font-size: rem(14);
     }

 }
