/* ==========================================================================
   Player Card Component
   ========================================================================== */

/**
 * c-player-card
 * This component styles the content of the player card
 */

.c-player-card{

    &__heading {
        font-size: rem(20);
    }

    &__photo {
        text-align: center;
    }

    &__image {
        width: 100%;
        max-width: rem(150);
        height: auto;
        border-radius: 50%;
    }
    // if no image, we include an svg
    &__no-user-icon {
        fill: $light-gray;
        width: 100%;
        height: auto;
        max-height: rem(100);
        max-width: rem(100);

        @include breakpoint(medium) {
            height: rem(70);
            width: rem(70);
        }
    }

    // Meta Highlight Area
    &__meta-highlights {
        @include breakpoint(small only) {
            margin-top: $global-margin;
        }
    }

    &__term {
        display: inline-block;
        margin-right: rem(15);
        margin-bottom: $global-margin;
        line-height: 1.8;
    }

    &__def {
        display: inline;
        font-weight: 400;
        margin-bottom: $global-margin;
        &:after {
            content: '';
            display: block;
        }
    }

    &__meta-icon {
        width: rem(30);
        height: rem(30);
        display: inline-block;
        vertical-align: bottom;
    }

}

/* Variations
   ========================================================================== */

   .c-player-card--dark {
        background-color: $black;
        color: $white;

        .c-player-card__meta-icon {
            fill: $white;
        }

        select {
            color: $white;
            border-color: $white;
            background-image: svg-url('<svg clip-rule="evenodd" style="fill:white" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.4142" version="1.1" viewBox="0 0 100 100" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(2.3117e-16 .81604 -.81604 2.3117e-16 109.57 -38.133)"><path d="m139.18 73c0-0.857-0.429-1.821-1.072-2.464l-49.928-49.929c-0.643-0.643-1.608-1.071-2.465-1.071s-1.821 0.428-2.464 1.071l-5.357 5.357c-0.643 0.643-1.072 1.607-1.072 2.465 0 0.857 0.429 1.821 1.072 2.464l42.107 42.107-42.107 42.107c-0.643 0.643-1.072 1.607-1.072 2.464 0 0.965 0.429 1.822 1.072 2.465l5.357 5.357c0.643 0.643 1.607 1.071 2.464 1.071s1.822-0.428 2.465-1.071l49.928-49.929c0.643-0.643 1.072-1.607 1.072-2.464z" fill-rule="nonzero"/></g></svg>');
        }

        .c-player-card__image {
            border: rem(2) solid $secondary-color;
        }

        .c-chart-circle__circle {
            border-color: $white;
        }

        .c-map__infowindow, .c-map__infowindow p {
            color: $body-font-color;
        }
   }
