/* ==========================================================================
/* ==========================================================================
   Card List Layout
   ========================================================================== */

/**
 * l-card-list
 * This component styles the card list content
 */

.l-card-list{
    max-width: rem(1174);
    list-style-type: none;
    margin: $global-margin auto;

    &__item {
        margin: 0 0 $global-margin 0;
        // This gives each card the same height if found in this card list layout.
        .c-card {
            height: 100%;
            margin: 0;
        }
    }
}
