/* ==========================================================================
   Tag list component
   ========================================================================== */

/**
 * c-tag-list
 * Styling for an unordered list that should look like a group of tags
 */

.c-tag-list{
    list-style-type: none;
    margin: rem(20 0);
    display: flex;
    flex-wrap: wrap;

    &__item {
        flex: 0 1 auto;
        margin: rem(10);
    }

    &__label {
        border-radius: $global-radius;
        background-color: $white;
        padding: rem(5 8);
        display: inline-block;
    }
}


