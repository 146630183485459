/* ==========================================================================
   Company Signage component
   ========================================================================== */


/**
 * c-company-sign
 * Logo and company contact information
 */

 .c-company-sign {
    display: flex;
    align-items: flex-start;

    &__logo {
        flex: 0 1 rem(100);
        margin-right: rem(10);
    }

    &__icon {
        width: rem(90);
        height: rem(112);
        position: relative;
        top: rem(-5);
    }

    &__content {
        flex: 1 1 auto;
        color: $white;

        @include breakpoint (small only) {
            margin-bottom: rem(50);

        }
    }

    &__link {
        color: $white;
    }

    &__address {
        font-style: normal;
        margin-bottom: $global-margin;

    }

    &__contact {
        margin-bottom: 0;
    }
 }
