/* ==========================================================================
   c-search-tab-nav
   ========================================================================== */

/**
 * c-search-tab-nav
 * Styling for the tab navigation on the Spielbetrieb pages
 */

.c-search-tab-nav {
    border: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__link {
        font-weight: $global-weight-bold;
        font-family: $header-font-family;
        transition: background 0.3s ease-in-out;

        &:focus {
            outline: none;
        }
    }

    .tabs-title {
        float: none;
        flex: 1 1 auto;
        background-color: $primary-light-2;

        //foundation styles the direct html el, need to override here
        > a {
            font-size: rem(20);
            text-align: center;
        }

        @include breakpoint(medium) {
            flex: 0 1 auto;
            background-color: transparent;

        }
    }

    @include breakpoint(medium) {
        flex-wrap: nowrap;

        .tabs-title {
            flex: 0 1 25%;
        }
    }

    &--align-left {
        justify-content: flex-start;
    }
}
