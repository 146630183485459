/* ==========================================================================
   District Logo Component
   ========================================================================== */

/**
 * c-logo-district
 * Styling for an inline svg
 */

.c-logo-district--tvm{

    .c-logo-district__outline {
        fill: $primary-color;
    }

    .c-logo-district__gradient-stop-0 {
        stop-color: $primary-color;
    }

    .c-logo-district__gradient-stop-1 {
        stop-color: $primary-neon;
    }

}


//--------- Color variations based on html district class

@each $name, $colors in $district-colors {

    .c-logo-district--#{$name} {

       .c-logo-district__outline {
            fill: map-get($colors, 'dark');
        }

        .c-logo-district__gradient-stop-0 {
            stop-color: map-get($colors, 'dark');
        }

        .c-logo-district__gradient-stop-1 {
            stop-color:  map-get($colors, 'light');
        }

    }

}

.c-logo-district--megamenu {
    width: rem(45);
    height: rem(50);
    display: inline;
}
