/* ==========================================================================
   Comparision Player Card Component
   ========================================================================== */

/**
 * c-comparison-card
 * This component styles the content of the player card
 */

.c-comparison-card{

    // if no image, we include an svg
    &__no-user-icon {
        fill: $light-gray;
        height: rem(150);
        width: rem(150);


        @include breakpoint(medium) {
            height: rem(60);
            width: rem(60);
        }
    }

    &__no-user-icon--highlight {
        border: rem(4) solid $secondary-color;
        border-radius: 50%;
    }

    &__photo {
        border: rem(4) solid transparent;
        border-radius: 50%;

        &--highlight {
            border: rem(4) solid $secondary-color;
        }
    }

    &__player {
        margin-bottom: $global-margin;
    }

    &__name {
        font-size: rem(20);
        font-weight: $header-font-weight;
        font-family: $header-font-family;
        margin: 0 0 0 rem(10);
    }

    // Tab navigation
    &__tabs-list {
        margin: 0 0 $global-margin 0;
        list-style-type: none;
    }

    &__tab {
        color: get-color(secondary);
        padding: rem(3 8);

        &:hover {
            color: $white;
        }

        &.is-active {
            color: $white;
            text-transform: uppercase;
            font-size: rem(20);
            font-weight: $header-font-weight;
            font-family: $header-font-family;
        }
    }

    &__switch {
        display: inline-block;
        cursor: pointer;
    }

    &__switch-icon {
        fill: get-color(secondary);
        width: rem(40);
        height: rem(40);
        display: inline-block;
        vertical-align: middle;
        transition: fill .3s ease-in-out;

        &:hover {
            fill: $white;
        }
    }

    &__user-item {
        background-color: $black;
        transition: background .3s ease-in-out;
        cursor: pointer;
        padding: rem(5);

        &:hover {
            background-color: get-color(primary);
        }
    }

    // User select area
    &__user-select-icon {
        width: rem(30);
        height: rem(30);
        fill: $white;
    }

    // add special formating to input fields
    .u-input {
        padding: rem(0 8);
    }
}

/* Variations
   ========================================================================== */


