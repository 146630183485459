/* ==========================================================================
   Chart bar
   ========================================================================== */

/**
 * c-chart-bar
 * The chart bar element as seen in a player card on the player details page
 */


.c-chart-bar {
    margin-bottom: $global-margin;
    font-size: $small-font-size;
    position: relative;

    &__value-group {
        display: flex;
        justify-content: space-between;
        position: absolute;
        z-index: 2;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
    }

    &__graphic {
        position: relative;
        z-index: 1;
    }

    &__bar-line {
        border-top: rem(1) solid $black;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 50%;
    }

    &__bar {
        position: relative;
        z-index: 5;
    }

    &__value {
        color: $black;
        font-weight: $header-font-weight;
        font-family: $header-font-family;
        font-size: rem(19);
        margin-left: rem(5);
    }

    &__max {
        color: $white;
        font-weight: $header-font-weight;
        font-family: $header-font-family;
        font-size: rem(19);
        margin-right: rem(5);
    }

    &--progress {
        .c-chart-bar__graphic {
            background-color: $dark-gray;
        }
    }

    &--background {
        padding: rem(30) rem(10) rem(10);
        position: relative;
        background: linear-gradient(to bottom, $black 0%, darken($secondary-color, 30%) 70%, $black 100%);
    }
}
