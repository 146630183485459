/* ==========================================================================
   Social Media Logo List component
   ========================================================================== */


/**
 * c-social-media
 * Controls styling of social media list used in footer
 */

.c-social-media {
    margin: $global-margin 0 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;

    @include breakpoint(medium) {
        margin-top: 0;
        position: relative;
        top: rem(23);
    }

    @include breakpoint(large) {
        top: inherit;
    }

    &__item {
        flex: 0 0 auto;
        margin-right: rem(30);

        &:last-child{
            margin-right: 0;
        }

        @include breakpoint(medium) {
            margin-right: rem(20);
        }
    }

    &__icon {
        width: rem(35);
        height: rem(35);
        fill: rgba($white,.8);
        transition: fill .3s ease-in-out;

        &:hover {
            fill: $secondary-color;
        }
    }

    &__link {
        display: inline-block;
    }
}