/* ==========================================================================
   Mobile Navigation Component
   ========================================================================== */

/**
 * c-mobile-nav
 * The site mobile navigation
 */

 .c-mobile-nav {
     flex: 0 0 100%;
     order: 3;
     overflow: hidden;
     transition: max-height .6s ease-in-out;
     background-color: $white;

    &.close {
        max-height: 0 !important;
     }

    .is-active {
        .c-mobile-nav__icon {
            transform: rotate(-90deg);
        }
    }

    &__list {
         margin: 0;
         list-style-type: none;
         border-bottom: rem(1) solid $primary-light-3;
    }

    &__item {

        border-top: rem(1) solid $primary-light-3;

        &:nth-child(2) {
            border-top: transparent;
        }

        &--mein-tvm {
            padding: rem(10 10 10 20);
        }
    }

     &__link {
         display: inline-block;
         vertical-align: middle;
         flex: 1 1 auto;
         padding: rem(10 10 10 20);
         color: $body-font-color;

         &:focus, &:active {
            color: $body-font-color;
         }

     }

     &__link-wrapper {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

     &__link-text {
         max-width: 90%;
     }

     &__icon-wrapper {
        padding: rem(10);
     }

     &__icon {
         fill: $black;
         width: rem(25);
         height: rem(25);
         transform: rotate(90deg);
         background-color: $primary-light-4;
         padding: rem(3);
         border-radius: $global-radius;

     }



 }

 .c-mobile-sub-nav {
        margin: 0;
        list-style-type: none;
        background-color: $primary-light-3;
        padding: rem(10 0);

        &__link {
            display: block;
            text-transform: uppercase;
            color: $body-font-color;
            font-weight: $header-font-weight;
            padding: rem(5 10 5 20);
            font-size: rem(14);
        }

 }
