/* ==========================================================================
   News filtering component
   ========================================================================== */

/**
 * c-newsfilters
 * Styling for the news overview archive listing
 */

.c-newsfilters {

     ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
     }

     li {
        display: block;
        font-size: rem(16);
        padding: rem(5);

        @include breakpoint(medium) {
            padding: rem(10);
        }

        @include breakpoint(large) {
            padding: 0;
        }
     }

     &__label {
        padding-right: rem(15);
        font-size: rem(16);
        cursor: pointer;
        white-space: nowrap;
        transition: color .3s ease-in-out;

        &:hover {
            color: $primary-color;
        }
     }

     &__filters {
        margin: rem(40) 0;
        background-color: $white;
        padding: rem(30 15 15);

        @include breakpoint(medium) {
            padding: rem(15 15 10);
        }
     }

     &__groups-list {
        justify-content: center;
        @include breakpoint(large) {
            justify-content: flex-end;
        }
     }

    &__categories-list {
        width: 100%;
        padding: $global-margin 0;
        justify-content: center;
        @include breakpoint(large) {
            justify-content: flex-start;
        }
    }

    &__results {
        min-height: rem(300); // have a min height that helps hide result transitions
    }
}

// district color loop for news filters
@each $name, $colors in $district-colors {

    .c-newsfilters__filter-#{$name} .u-colored-checkbox__checkmark {
        background-color:  map-get($colors, 'dark');
    }

    .c-newsfilters__filters .c-newsfilters__filter-#{$name}:hover input ~ .u-colored-checkbox__checkmark {
        background-color: lighten(map-get($colors, 'dark'), 15%);
    }
}
