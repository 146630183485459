/* ==========================================================================
   Sidebanner Styles
   ========================================================================== */

/**
 * c-sidebanner
 * The sidebanner is seen on the right side of the screen above the content. It contains graphical links.
 */

.l-sidebanner {
    position: absolute;
    right: 0;
    top: rem(314); // based on height of small header on large screen
    z-index: 5;
}

.c-sidebanner {
    background-color: $white;
    width: rem(60);

    &__content {
        width: rem(60);
    }

    &__link {
        display: block;
        margin: 0;
        line-height: 0;

        &--youtube {
            .c-sidebanner__icon {
                fill: $youtube;
            }

        }

        &--sharing {
            cursor: pointer;
        }
    }

    &__icon--tennispoint {
        width: rem(60);
        height: rem(157);
    }

    &__icon {
        width: rem(60);
        height: rem(60);
        opacity: 1;
        transition: opacity .3s ease-in-out;

        &:hover {
            opacity: .8;
        }


    }

}
