.browserupgrade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
    text-align: center;
}


body {
    visibility: visible;
    opacity: 1;
    overflow-x: hidden;
}


hr {
    margin: $global-margin 0;
}

.l-page-wrapper {
    padding-top: rem(90); // pushes content under the header
    min-height: 100vh;
    height: 100vh;
    position: relative;
    // using Flexbox to keep footer at bottom of page
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint(medium) {
        padding-top: rem(120);
    }

}

.l-page-header {
    width: 100%; // Edge and Firefox support
}

.l-page-content {
    width: 100%; // Edge and Firefox support
    flex: 1 0 auto;
    overflow-x: hidden;
    &--dark {
        background-color: $black;
    }
}

.l-page-footer {
    width: 100%; // Edge and Firefox support
    flex: 0 0 auto;
    overflow-x: hidden;
}

