
/* use rem-calc for line-height - foundation-sites uses unitless-calc here */
@each $size, $headers in $header-styles {
    @include breakpoint($size) {
        @each $header, $header-defs in $headers {
            $font-size-temp: 1rem;
            #{$header} {
                @if map-has-key($header-defs, font-size) {
                    $font-size-temp: rem-calc(map-get($header-defs, font-size));
                } @else if map-has-key($header-defs, fs) {
                    $font-size-temp: rem-calc(map-get($header-defs, fs));
                }
                @if map-has-key($header-defs, line-height) {
                    line-height: rem-calc(map-get($header-defs, line-height), $font-size-temp);
                } @else if map-has-key($header-defs, lh) {
                    line-height: rem-calc(map-get($header-defs, lh), $font-size-temp);
                } @else if $size == $-zf-zero-breakpoint {
                    line-height: rem-calc($header-lineheight, $font-size-temp);
                }
            }
        }
    }
}

// show href value when a tag has no text value (https://twitter.com/AllThingsSmitty/status/946381631836901377)
a[href^="http"]:empty::before {
    content: attr(href);
}

figure {
    margin: 0 0 $global-margin;
}

legend {
    padding: rem(0 4);;
    margin-left: rem(-4);
}

.is-error {
    color: get-color(alert);
    border: rem(2) solid get-color(alert);
    padding: rem(16 32);
    font-weight: $global-weight-bold;
}

.is-success {
    color: get-color(success);
    border: rem(2) solid get-color(success);
    padding: rem(16 32);
    font-weight: $global-weight-bold;
}

a {
    transition: color 0.3s ease-in-out;
}

dl {
    dt {
        font-weight: 400;
        font-size: rem(13);
        line-height: 1;
     }

     dd {
         font-weight: $global-weight-bold;
         margin-bottom: $global-margin;
     }
}

blockquote {
    background-color: $white;
}

// District colors for text


// Background colors

@each $name, $colors in $district-colors {

    .u-color-district--#{$name} {
        color: map-get($colors, 'dark') !important;
    }

    .u-bg-color-district--#{$name} {
        background-color: map-get($colors, 'dark');
        color: $white;
        a {
            color: currentColor;
        }
    }
}

