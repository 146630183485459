/* ==========================================================================
   Header Banner component
   ========================================================================== */


/**
 * c-header-banner
 * A ad banner that is shown at the top of the screen on mobile only
 */

 .c-header-banner {
    position: relative;
    box-shadow: $global-shadow;
    z-index: 10;
    background-color: $white;
    display: none;

    &__content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: rem(20 10);
    }

    &__text {
        flex: 0 0 auto;
        font-weight: 700;
        font-size: rem(13);
    }

    &__image {
        flex: 1 1 auto;
        display: flex;
        align-items: center;

    }

    &__icon {
        width: rem(142);
        height: rem(28);
        display: inline-block;
        flex: 0 0 auto;
    }

    &__close-btn {
        flex: 0 0 rem(30);
        justify-content: flex-end;
        display: flex;
        align-items: center;
    }

    &__close-icon {
        width: rem(20);
        height: rem(20);
        fill: $black;
        position: relative;
        z-index: 60; // higher z-index than the link utility
    }

    &__link {
        margin-right: rem(40);
    }
 }
