/* ==========================================================================
   Breadcrumbs Layout
   ========================================================================== */

/**
 * l-breadcrumbs
 * This component styles the card list content
 */

.l-breadcrumbs{
    background-color: rgba($white,.8);
    height: rem(40);
    margin-top: rem(-40);
    z-index: 5;
    position: relative;
    display: flex;
    align-items: center;

    &__list {
        max-width: map-get($breakpoints, xxlarge);;
        padding: 0 rem(20);
        list-style-type: none;
        flex: 0 0 100%;
        display: flex;
        margin: auto;
    }

    &__item {
        flex: 0 0 auto;
        margin-right: rem(10);

        &:after {
            content: '-';
            display: inline-block;
            margin: rem(0 5);
        }

        &:first-child {
            font-weight: $header-font-weight;
            margin-right: rem(5);

            .c-breadcrumbs-link {
                color: $primary-color;
            }
        }

        &:last-child {
            margin-right: 0;
            &:after {
                content: '';
            }
        }
    }
}

/* Variations
   ========================================================================== */

// no hero breadcrumbs

.l-breadcrumbs--no-hero {
    margin-top: 0;
}

// dark theme

.l-breadcrumbs--dark {
    background-color: $dark-gray-1;
    color: $light-gray;

    .l-breadcrumbs__item:first-child {
        .c-breadcrumbs-link {
            color: $light-gray;
        }
    }

    .l-breadcrumbs__item {
        .c-breadcrumbs-link {
            &.is-current {
                color: $secondary-color;
            }
        }
    }
}
