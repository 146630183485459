/* ==========================================================================
   Legend styling - used on the Dashboard
   ========================================================================== */

/**
 * c-legend
 * This component styles the lengend labels on the Dashboard - could be used elsewhere, that's why its own namespace
 */

.c-legend{

    &-circle {
        border-radius: 50%;
        width: rem(18);
        height: rem(18);
        display: inline-block;
        vertical-align: middle;

        &--secondary {
            background-color: get-color(secondary);
        }

        &--tertiary {
            background-color: $dark-gray;
        }
    }

    &-label {
        font-size: $small-font-size;
        vertical-align: middle;
        margin-right: $global-margin;
    }
}

/* Variations
   ========================================================================== */


