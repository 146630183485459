/* ==========================================================================
   Logo component
   ========================================================================== */


/**
 * c-logo
 * Controls styling and placement of Logo in the header
 */

.c-logo {
    display: block;
    width: rem(253);
    height: rem(90);
    background-repeat: no-repeat;
}



