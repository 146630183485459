/* ==========================================================================
   lead
   ========================================================================== */

/**
 * base styles
 */

.c-lead p, .lead, .c-lead {
    font-family: $lead-font-family;
    font-weight: $lead-font-weight;
    color: $lead-font-color;
    font-size: $body-font-size;
    line-height: $lead-lineheight;

    @include breakpoint(medium) {
        font-size: $lead-font-size;
    }
}
