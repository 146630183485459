/* ==========================================================================
   Box Card Component
   ========================================================================== */

/**
 * c-box-card
 * This component styles the content of the competition box card. Used on the tournament detail page
 */

.c-box-card{
    text-align: center;
    font-family: $header-font-family;
    font-weight: $global-weight-bold;
    padding: rem(15);
    margin: 0;

    &__text {
        margin: 0;
    }
}
