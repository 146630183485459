/* ==========================================================================
   Footer layout
   ========================================================================== */

/**
 * base styles
 */

 .l-page-footer {
     background-color: $black;
     display: flex;
     justify-content: space-between;
     flex-direction: column;
     height: auto;


     > div {
        flex: 0 0 auto; // vertically align main elements to fill the height of the footer
     }

    &__partners {
        background-color: $dark-gray-1;
        padding-top: $global-margin;
    }

    &__contact {
        padding: $global-margin 0;
    }

    &__meta {
        background-color: $primary-color;
        color: $white;
        padding: rem(30) 0 rem(100) 0;
         // gives room to bottom of page for the scroll to top button

        @include breakpoint(medium) {
            padding-bottom: rem(26);
        }

    }

    &__partner-title {
        color: $light-gray-1;
        text-transform: uppercase;
        letter-spacing: rem(1);
        font-size: $small-font-size;
        font-family: $header-font-family;
        font-weight: 700;
        border-bottom: rem(1) solid $light-gray-1;
    }
}
