/* ==========================================================================
   Hero
   ========================================================================== */

/**
 * c.hero
 * Styling for hero area of website
 */

.c-hero{
    position: relative;
    display: flex;// Fixed IE 11 position problem with flex
    flex-direction: row; // Fixed IE 11 position problem with flex

    &__background {
        margin: 0;
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        &:before {
            @include image-effect-base;

        }
    }

    &__background-image {
        filter: grayscale(100%);
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top center;
    }

    &__content {
        position: relative;
        z-index: 2;
        text-align: center;
        width: 100%;
        max-width: $global-width;
        margin: auto;
        padding: rem(20 0);
        color: $white;

        @include breakpoint(medium) {
            min-height: rem(234);
        }

        // settings for vertically and horizontally centering hero content
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    &__heading {
        text-transform: uppercase;
        //font-size: rem(36);
        letter-spacing: rem(2);
        font-family: $header-font-family;
        font-weight: $header-font-weight ; // trigger black font
        text-shadow: $text-shadow;
        margin-bottom: $global-margin*1.8;
        margin-top: $global-margin*1.5;

        @include fluid-type(320px, 1024px, 20px, 36px);

    }

    &__number {
        font-size: rem(80);
        line-height: 1;
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        display: block;
        margin-bottom: rem(15);
        text-shadow: $text-shadow;

        @include breakpoint(medium) {
            font-size: rem(50);
        }

        @include breakpoint(large) {
            font-size: rem(80);
        }
    }

    &__group {
        font-size: rem(20);
        display: block;
        font-weight: 300;
        text-shadow: $text-shadow;
    }

    &__logo {
        width: rem(111);
        height: rem(133);
        // shadow creates extra space. We need to push the logo a bit to the right
        position: relative;
        left: rem(10);
    }

    .c-slider--smallonly {
        flex: 0 0 100%; // gives contents 100% width
    }
}

/* Variations
   ========================================================================== */

.c-hero--statistics {

    .c-hero__content {
        padding: rem(84 0);
    }
}

html.u-header-type--transparent {

    .c-hero__content {
        padding-top: rem(214);
    }
}

//---- hero with image
.c-hero--district-logo {

    @include breakpoint(medium) {
        .grid-x {
            max-width: rem(780); // screen design calls for less open space between elements
            margin: auto;
        }
    }
}

//--------- Color variations based on html district class

@each $name, $colors in $district-colors {

    // class gets set on the html tag
    .is-#{$name} {

        .c-hero__background:before {
            @include district-background-color($colors);
        }
    }
}
