/* ==========================================================================
   SlideToggler
   ========================================================================== */

/**
 * c-slide-toggler
 * Uses the JavaScript file slide-toggle.js to add max-height
 * Instructions:
 * Button requires the data attribute data-slidetoggler-button
 * Outer div container uses class c-slide-toggler
 * 
 */

.c-slide-toggler {

    overflow: hidden;
    transition: max-height .3s ease-in-out;

    // max height is calculated by javaScript in order to make
    // the height variable. 
    
    &__content {
        height: 100%;
    }

    &.close {
        max-height: 0 !important;
    }
}